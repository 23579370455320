import React from "react";
import { Link } from "react-router-dom";


import "./styles/menu.css";
import MenuModal from "../common/model";

const Menu = (props) => {
	const { logo, title, description, linkText, link, details } = props;

	return (
		<React.Fragment>
			<div className="project">
				<Link to={link}>
					<div className="project-container">
						<div className="project-logo">
							<img src={logo} alt="logo" style={{width: "250%"}} />
						</div>
						<div className="project-title">{title}</div>
						<div className="project-description" style={{whiteSpace: 'pre-line'}}>{description}</div>
						<MenuModal
							linkText={linkText}
							link={link}
							details={details}
						/>
					</div>
				</Link>
			</div>
		</React.Fragment>
	);
};

export default Menu;
