import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { MaterialReactTable } from 'material-react-table';
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import AllMenus from "../components/menus/allMenus";

import INFO from "../data/user";
import SEO from "../data/seo";
import MENU from "../data/menu";

import "./styles/menus.css";

const Menus = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "menus");

	const columns = useMemo(
		//column definitions...
		() => MENU.columns,
		[],//end
	);

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Menu | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="projects-container">
						<div className="title projects-title">
							Specials from theEarthenPot's Kitchen ...
						</div>

						<div style={{paddingTop: "100px", paddingBottom: "100px"}}>
							<MaterialReactTable
								columns={columns}
								data={MENU.planData}
								enableColumnActions={false}
								enableColumnFilters={false}
								enablePagination={false}
								enableSorting={false}
								enableBottomToolbar={false}
								enableTopToolbar={false}
							/>
						</div>

						<div className="projects-list">
							<div style={{ fontFamily: "Hubballi", fontSize:"30px" }}>
								Weekly Specials !!!
							</div>

							<AllMenus />
						</div>

						<div>
							Also check our <Link to="/menu-alacarte">à la carte menu</Link> ...
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Menus;
