import React from "react";
import { faPhoneFlip } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faPhoneFlip}
				title="Contact"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="./instagram.png"
								alt="instagram"
								className="work-image"
							/>
							<div className="work-title">@_theearthenpot</div>
							<div className="work-subtitle">
								Food / Recipe Instagram
							</div>
							<div className="work-duration"></div>
						</div>
						
						<div className="work">
							<img
								src="./whatsapp.png"
								alt="mail"
								className="work-image"
							/>
							<div className="work-title">+1 343-558-9630</div>
							<div className="work-subtitle">
								Delivery times: Between 6:45 pm to 8:00 pm <br />
								Request orders 48 hrs prior <br />
								Service Areas: Ottawa South <br />
							</div>
							<div className="work-duration"></div>
						</div>
						
					</div>
				}
			/>
		</div>
	);
};

export default Works;
