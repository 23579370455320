const SEO = [
	{
		page: "home",
		description:
			"the earthen pot … a humble kitchen serving home-made comfort food blended with flavours from Indian Sub-continent - Gujarati, Maharashtrian, Urban North Indian & Urban South Indian cuisines",
		keywords: ["Dhara", "theearthenpot", "tiffin", "homemade indian food", "food service", "ottawa", "made to order"],
	},

	{
		page: "about",
		description:
			"the earthen pot … a humble kitchen serving home-made comfort food blended with flavours from Indian Sub-continent - Gujarati, Maharashtrian, Urban North Indian & Urban South Indian cuisines",
		keywords: ["Dhara", "theearthenpot", "tiffin", "homemade indian food", "food service", "ottawa", "made to order"],
	},

	{
		page: "insights",
		description:
			"the earthen pot … a humble kitchen serving home-made comfort food blended with flavours from Indian Sub-continent - Gujarati, Maharashtrian, Urban North Indian & Urban South Indian cuisines",
		keywords: ["Dhara", "theearthenpot", "tiffin", "homemade indian food", "food service", "ottawa", "made to order"],
	},

	{
		page: "menus",
		description:
			"the earthen pot … a humble kitchen serving home-made comfort food blended with flavours from Indian Sub-continent - Gujarati, Maharashtrian, Urban North Indian & Urban South Indian cuisines",
		keywords: ["Dhara", "theearthenpot", "tiffin", "homemade indian food", "food service", "ottawa", "made to order"],
	},

	{
		page: "contact",
		description:
			"If you're interested in ordering meal from theearthenpot, feel free to reach out to me. I'm would be glad to serve you.",
		keywords: ["Dhara", "theearthenpot", "tiffin", "homemade indian food", "food service", "Ottawa", "made to order"],
	},
];

export default SEO;
