import React from "react";

import Menu from "./menu";
import MENU from "../../data/menu";

import "./styles/menus.css";

const AllMenus = () => {
	return (
		<div className="all-projects-container">
			{MENU.weeklyMenu.map((special, index) => (
				<div className="all-projects-project" key={index}>
					<Menu
						logo={special.logo}
						title={special.title}
						description={special.description}
						linkText={special.linkText}
						link={special.link}
						details={special.details}
					/>
				</div>
			))}
		</div>
	);
};

export default AllMenus;
