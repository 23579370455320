import React from "react";

function insights_1() {
	return {
		date: "16 July 2023",
		title: "The beginning of theearthenpot",
		description:
			"the earthen pot ... a humble kitchen is an attempt to share my passion for food with people around me through some inherited, taught & learnt recipes.",
		keywords: [
			"Beginning",
			"theearthenpot",
			"Dhara"
		],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
					font-family: 'Hubballi';
					font-size: 25px;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">the Earthen Pot ... a humble kitchen.</div>
					<div>
						A humble attempt to share my passion for food with people around me through some inherited, taught & learnt recipes. The flavours I bring to you are inherited from my grandmother & my father, some taught by my Mother-in-law & some I learnt from watching & following respected chefs from all over the world.

						We the millennials are possibly one of the most fortunate generations who got to learn the old-school way from our grandmas' and mothers' traditional kitchens and chefs across the world through television & social media.

						In this day & age where we are juggling between the challenges of life, I find solace in cooking. I enjoy slowing down, cooking from scratch & prepping the simplest of ingredients to create soulful dishes that bring you depth of flavours & well-blended taste. Traditionally, most cuisines from Indian Subcontinent are based on the THALI culture – A meal prepared with seasonal ingredients designed to provide the utmost taste, comfort & balanced nutrition including the macros & micros. A simple vegetarian Thali would include a variety of Bread, Rice, Lentils & Legumes providing the macros like carbohydrates & proteins, Vegetable curries with or without gravy supplementing Vitamins & Roughages, yogurt, salads & pickles provding probiotics & prebiotics, and last but certainly an important part- an occasional dessert giving the comfort to the soul. My style of cooking is designed based on the thali concept including some or all components, largely vegetarian, blended with flavours from Indian Sub-continent - Gujarati, Maharashtrian, Urban North Indian & Urban South Indian cuisines.

						I believe that food brings people together, creates memories & creates a strong bond between generations & communities.
					</div>
				</div>
			</React.Fragment>
		),
	};
}

/*
function insights_2() {
	return {
		date: "18 July 2023",
		title: "The beginning of theearthenpot 2",
		description:
			"the earthen pot ... a humble kitchen is an attempt to share my passion for food with people around me through some inherited, taught & learnt recipes.",
		keywords: [
			"Beginning",
			"theearthenpot",
			"Dhara"
		],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
					font-family: 'Hubballi';
					font-size: 25px;
				}
				
				.image-container {
					width: 450px;
					height: 450px;
				}
				
				.image-wrapper {
					overflow: hidden;
					transform: rotate(3deg);
				
					background: #ffffff;
					display: inline-block;
					margin: 55px 75px 30px;
					padding: 15px 15px 30px;
					text-align: center;
					text-decoration: none;
					-webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
					-moz-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
					box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
					-webkit-transition: all .20s linear;
					-moz-transition: all .20s linear;
					transition: all .20s linear;
					z-index: 0;
					position: relative;
				}
				
				.image-wrapper img {
					width: 100%;
					display: block;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">the Earthen Pot ... new blog</div>
					<div className="image-wrapper">
						<img src={"../masala.jpg"} className="img"/>

					</div>
					<div>
						<p>vewvfrerf</p>

						<p>rqjlkgho2erhg</p>

						<p>rkjgbwleirbgiklrg</p>

						<p>wkrjgbfelwirgbu</p>
					</div>
				</div>
			</React.Fragment>
		),
	};
}
*/

const myInsights = [insights_1] //, insights_2];

export default myInsights;
