const INFO = {
	main: {
		title: "theearthenpot a humble kitchen",
		name: "Dhara Patel",
		email: "info@theearthenpot.com",
		logo: "../logo.jpg",
	},

	socials: {
		instagram: "https://www.instagram.com/_theearthenpot/",
		whatsapp: "https://whatsapp.com/"
	},

	homepage: {
		title: "theearthenpot",
		description:
			"the earthen pot .... a humble kitchen. a humble attempt to share my passion for food with people around me through some inherited, taught & learnt recipes.",
		logo: "../tepicon.png"
	},

	about: {
		title: "I’m Dhara, the owner of the earthen pot ...",
		description:
			"the earthen pot .... a humble kitchen. a humble attempt to share my passion for food with people around me through some inherited, taught & learnt recipes. The flavours I bring to you are inherited from my grandmother & my father, some taught by my Mother-in-law & some I learnt from watching & following respected chefs from all over the world. \n\n" +
			"We the millennial are possibly one of the most fortunate generations who got to learn the old-school way from our grandmas' and mothers' traditional kitchens and chefs across the world through television & social media. \n\n" +
			"In this day & age where we are juggling between the challenges of life, I find solace in cooking. I enjoy slowing down, cooking from scratch & prepping the simplest of ingredients to create soulful dishes that bring you depth of flavours & well-blended taste. Traditionally, most cuisines from Indian Subcontinent are based on the THALI culture – A meal prepared with seasonal ingredients designed to provide the utmost taste, comfort & balanced nutrition including the macros & micros. A simple vegetarian Thali would include a variety of Bread, Rice, Lentils & Legumes providing the macros like carbohydrates & proteins, Vegetable curries with or without gravy supplementing Vitamins & roughages, yogurt, salads & pickles providing prebiotics & probiotics, and last but certainly an important part- an occasional dessert giving the comfort to the soul. My style of cooking is designed based on the thali concept including some or all components, largely vegetarian, blended with flavours from Indian Sub-continent - Gujarati, Maharashtrian, Urban North Indian & Urban South Indian cuisines. \n\n" +
			"I believe that food brings people together, creates memories & creates a strong bond between generations & communities. \n\n",
	},

	articles: {
		title: "An insight into the humble kitchen",
		description:
			"Chronological collection of my long-form ",
	}
};

export default INFO;
