import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";

import INFO from "../data/user";
import SEO from "../data/seo";
import MENU from "../data/menu";

import "./styles/menus.css";

const MenusAlaCarte = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "menus");

	const columns = useMemo(
		//column definitions...
		() => MENU.columns,
		[],//end
	);

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Menu | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="projects-container">
						<div className="title projects-title">
							Specials a la carte menu from theearthenpot's kitchen ...
						</div>

						<div className="subtitle projects-subtitle">
							
						</div>

						<div>
							<img
								src="../theearthenpot_a_la_carte_menu_p1.jpg"
								alt="theearthenpot_menu_alacart_p1"
								style={{height: "100%", width: "100%"}}
							/>
						</div>
						<div>
							<img
								src="../theearthenpot_a_la_carte_menu_p2.jpg"
								alt="theearthenpot_menu_alacart_p2"
								style={{height: "100%", width: "100%"}}
							/>
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default MenusAlaCarte;
