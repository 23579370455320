const MENU = {
    columns: [
        {
            accessorKey: 'plan', //access nested data with dot notation
            header: 'Plan',
            size: 100,
        },
        {
            accessorKey: 'bread',
            header: 'Roti / Bread',
            size: 100,
        },
        {
            accessorKey: 'veggie', //normal accessorKey
            header: 'Veggies/Curries',
            size: 50,
        },
        {
            accessorKey: 'soup',
            header: 'Lentils/Soup',
            size: 50,
        },
        {
            accessorKey: 'rice',
            header: 'Rice',
            size: 50,
        },
        {
            accessorKey: 'extras',
            header: 'Extras',
            size: 100,
        },
        {
            accessorKey: 'price',
            header: 'Price per Tiffin',
            size: 75,
        }
    ],

    planData: [
        {
            plan: "Just About Right",
            bread: "3 pieces",
            veggie: "8 Oz",
            soup: "8 Oz",
            rice: "",
            extras: "1/2 Cup Salad",
            price: "CAD $15.00"
        },
        {
            plan: "Balanced",
            bread: "4 pieces",
            veggie: "8 Oz",
            soup: "8 Oz",
            rice: "8 Oz",
            extras: "1/2 Cup Salad",
            price: "CAD $17.00"
        },
        {
            plan: "I'm Hungry!",
            bread: "6 pieces",
            veggie: "8 Oz",
            soup: "10 Oz",
            rice: "8 Oz",
            extras: "1 Cup Salad",
            price: "CAD $19.00"
        },
        {
            plan: "Feast Time!",
            bread: "8 pieces",
            veggie: "10 Oz",
            soup: "12 Oz",
            rice: "12 Oz",
            extras: "1 Pappad & 1 Cup Salad",
            price: "CAD $21.00"
        }
    ],

    weeklyMenu: [
        {
            title: "Kick off Monday",
            description:
                "Phulka Roti\nGrated Carrot Sabji\nGujarati Kadhi\nGhee Rice",
            logo: "../monday.jpg",
            
        },

        {
            title: "Happy Tuesday !!!",
            description:
                "Phulka Roti\nDahi Cauliflower\nKhatta Moong\nGhee Rice",
            logo: "../tuesday.jpg",
            
        },

        {
            title: "Let's crush Wednesday",
            description:
                "Phulka Roti\nAaloo Methi\nDaal Fry\nGhee Rice",
            logo: "../wednesday.jpg",
            
        },

        {
            title: "Near to Friday",
            description:
                "Phulka Roti\nPindi Chole\nGhee Rice\nCucumber Raita",
            logo: "../thursday.jpg",
           
        },

        {
            title: "TGIF",
            description:
                "Sabudana Khichadi\nGarlic Chutney\nWhipped Yogurt",
            logo: "../friday.jpg",
            
        },
    ]
}
export default MENU;